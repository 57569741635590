// Use this style sheet to define global variables and functions
// Add new fonts into the project
@font-face {
  font-family: FlamaBook;
  src: url(assets/fonts/FlamaBook.otf) format('opentype');
}
@font-face {
  font-family: Flama Medium;
  src: url(assets/fonts/Flama-Medium.otf) format('opentype');
}

// Convert px to rem
@function pxToRem($value) {
	$remValue: ($value / $font-base) * 1rem;
	@return $remValue;
}

// 字体大小
$font-base: 14px;
$font-large: pxToRem(32px);
$font-medium: pxToRem(24px);
$font-small: pxToRem(16px);
// 公共颜色设置
$white: #FFFFFF;
$black: #000000;
$grey-0: #E5E5E5;
$grey-1: #D7D7D7;
$grey-2: #808080;
$green-0: #1CAF9A;
$green-1: #45B6AF;
$placeholder: #999999;
$primary-color: #578EBE;
$danger-color: #FF8080;
$success-color: #45B6AF;
$confirm-color: #00ADEF;
$primary-dark-color: #364150;
$reset: #EBB846;
$placeholderSystem: rgba(0, 0, 0, 0.42);
$cancel: #E5E5E5;
$helm-grey-button: #C0C0C0;
// 圆角大小
$radius: pxToRem(5px);
// 字体家族
$primary-font-family: FlamaBook;
$bold-font-family: Flama Medium;