// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
@import "variables.scss";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darkerbt
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat-light-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-accent,
			warn: $app-warn,
			)
		)
	);

@import '~primeicons/primeicons.css';
@import '~primeng/resources/themes/bootstrap4-light-blue/theme.css';
@import '~primeng/resources/primeng.min.css';

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
	height: 100%;
	font-size: 1rem;
}
body {
	margin: 0;
	font-family: $primary-font-family !important;
}

// This defines how large 1rem is in the project
html {
	font-size: $font-base;
	background-color: $white;
	font-family: $primary-font-family !important;
}
// 背景色设置
.content {
	width: auto;
	// padding-left: 1rem;
	// padding-right: 1rem;
	// padding-bottom: 2rem;
	// height: calc(100% - 2rem);
	// width: calc(100% - 1.5rem);
}
// 页面容器
.container {
	background-color: $white;
	border-radius: $radius;
}
// angular material 组件颜色
.primary {
	background-color: $primary-color;
	color: $white;
}
.success {
	background-color: $success-color;
	color: $white;
}
.confirm {
	background-color: $confirm-color;
	color: $white;
}
.cancel {
	background-color: $white;
	color: $confirm-color;
	border: 1px solid $confirm-color !important;
}
.btn-helm-grey {
	background-color: $helm-grey-button;
	color: $black !important;
}
.btn-fixed-width {
	width: pxToRem(108px);
}
.input-display {
	height: 3rem;
	line-height: 3rem;
	border-radius: $radius;
	border: 1px solid #D7D7D7;
	width: 100%;
	background-color: #F4F4F4;
	font-size: pxToRem(16px);
	font-family: $primary-font-family;
	padding: 1px 0 1px pxToRem(10px);
	display: flex;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
}
// 输入框
input {
	border: 1px solid #CED4DA;
	height: 3rem;
	padding-left: pxToRem(10px);
	border-radius: $radius;
	width: 100%;
	font-size: pxToRem(16px);
	font-family: $primary-font-family !important;
}
input:focus {
	outline: $primary-color;
}
input::placeholder {
	font-size: pxToRem(16px) !important;
	color: $placeholder;
}
input:disabled,
textarea:disabled {
	background-color: #F4F4F4;
}
// 修改输入框占位符的字体大小、颜色
::-webkit-input-placeholder {
	/* WebKit browsers */
	// font-weight: bold;
	font-size: pxToRem(16px);
	color: $placeholder;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	// font-weight: bold;
	font-size: pxToRem(16px);
	color: $placeholder;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	// font-weight: bold;
	font-size: pxToRem(16px);
	color: $placeholder;
}
:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	// font-weight: bold;
	font-size: pxToRem(16px);
	color: $placeholder;
}

textarea {
	border: 1px solid $grey-1;
	height: 3rem;
	padding: pxToRem(5px) pxToRem(10px);
	border-radius: $radius;
	font-size: pxToRem(16px);
	font-family: $primary-font-family;
}

.p-inputnumber,
.p-calendar,
.p-dropdown,
.p-multiselect {
	width: 100%;
}

.p-multiselect .p-multiselect-label.p-placeholder,
.p-dropdown .p-dropdown-label.p-placeholder {
	font-family: $primary-font-family;
	color: $placeholder;
	font-size: pxToRem(16px);
}

.p-inputnumber-input,
.p-calendar .p-inputtext,
.p-dropdown .p-dropdown-label,
.p-dropdown.p-dropdown-clearable .p-dropdown-label,
.p-multiselect .p-multiselect-label,
.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item,
.p-multiselect-panel .p-multiselect-items .p-multiselect-item,
.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-inputtext,
.p-multiselect-panel .p-multiselect-items .p-multiselect-empty-message {
	font-family: $primary-font-family;
	font-size: pxToRem(16px);
	line-height: pxToRem(25px);
}

.p-datepicker .p-datepicker-header .p-datepicker-title {
	font-family: $bold-font-family;
}

.p-datepicker table th {
	font-family: $bold-font-family;
}

.p-datepicker table td > span {
	font-family: $primary-font-family;
}

.p-button.p-button-text {
	font-family: $primary-font-family;
	color: $confirm-color;
}

.p-button.p-button-text:enabled:hover {
	color: $confirm-color;
}

.p-datepicker table td > span.p-highlight {
	background: $confirm-color;
}

.p-multiselect-panel .p-multiselect-header .p-multiselect-close {
	display: none;
}

// 按钮
button {
	height: 3rem;
	border-radius: $radius;
}
.normal-btn {
	border: 1px solid $primary-dark-color !important;
}
// 下拉框

.content-style {
	font-size: 1rem;
	padding: 2rem !important;
}

// 页面标题 + 导航栏
// .page-title {
//   // padding: 2rem;

//   .title {
//     color: $primary-dark-color;
//     font-size: 2rem;
//     font-weight: bold;
//   }
// }
.page-bar {
	// width: 100%;
	// background: #F7F7F7;

	.page-breadcrumb {
		// cursor: pointer;
		display: inline-block;
		float: left;
		// margin-top: 10px;
		list-style: none;
		padding: 10px;
		width: 100%;
		background: #F2F2F2;
		max-width: -webkit-fill-available;
		font-size: 14px;
		letter-spacing: 0.5px;
		li {
			display: inline-block;
			span {
				color: #888888;
				font-size: 12px;
				text-shadow: none;
			}
			mat-icon {
				color: #AAAAAA;
				font-size: 1.6rem;
				text-shadow: none;
				vertical-align: bottom;
			}
			.arrow {
				vertical-align: middle;
			}
		}
		li:focus {
			outline: none !important;
		}
	}
}

// 搜索板块
.portlet {
	clear: both;
	color: #FFFFFF;
	font-size: 1.4rem;
	border: solid 1px $primary-color;
	mat-icon {
		color: #FFFFFF;
		font-size: 1.8rem;
		text-shadow: none;
		vertical-align: bottom;
	}
	.portlet-title {
		background-color: $primary-color;

		border-bottom: 1px solid #EEEEEE;
		padding: 0;
		margin-bottom: 10px;
		min-height: 41px;

		.caption {
			padding-top: 5px;
			padding-left: 5px;
			font-size: 15px;
			> mat-icon {
				padding-top: 2px;
			}
		}
	}
	.portlet-body {
		clear: both;
		color: #000000;
	}
}

// 单选按钮修改样式 下拉样式颜色修改
::ng-deep {
	.mat-radio-button {
		font-family: $primary-font-family;
		font-size: 14px;
		color: #383E42;
		padding-right: 7px;
		padding-left: 5px;
	}

	.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
		border-color: #578EBE;
	}

	.mat-radio-button.mat-accent .mat-radio-inner-circle,
	.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
	.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
	.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
		background-color: #578EBE;
	}

	.mat-pseudo-checkbox-checked,
	.mat-pseudo-checkbox-indeterminate,
	.mat-accent .mat-pseudo-checkbox-checked,
	.mat-accent .mat-pseudo-checkbox-indeterminate {
		background: #578EBE;
	}
}

// 距离顶部20px
.top-margin-20 {
	margin-top: 20px;
}

::ng-deep {
	// 重置按钮样式
	// 按钮颜色、边角设置
	.btn-brand {
		background: $cancel !important;
		border-radius: 0.333rem;
		color: #333333;
		border: none;
		width: 7rem;
		height: 3rem;
		margin-right: 1rem;
		cursor: pointer;
		font-size: 16px;
		span {
			display: inline-block;
			height: 3rem;
			line-height: 3rem;
		}
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-brandFields {
		background: $reset !important;
		border-radius: 0.333rem;
		color: #FFFFFF;
		border: none;
		width: 7rem;
		height: 3rem;
		margin-right: 1rem;
		cursor: pointer;
		font-size: 16px;
		span {
			display: inline-block;
			height: 3rem;
			line-height: 3rem;
		}
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-brandDetail {
		// background: $cancel !important;
		// border-radius: 0.333rem;
		// color: #FFFFFF;
		// border: none;
		// width: 7rem;
		// height: 3rem;
		// margin-right: 1rem;
		// cursor: pointer;
		// font-size: 16px;

		padding: 0 20px;
		height: 33px;
		line-height: 34px;
		border: none;
		background: $cancel !important;
		color: #333333;
		cursor: pointer;
		font-size: 1.142857rem;
		margin-right: 0.5rem;
		// span {
		//   display: inline-block;
		//   height: 3rem;
		//   line-height: 3rem;
		// }
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-brand:hover {
		background-color: $cancel !important;
	}

	.btn-brand:focus,
	.btn-secondary:focus,
	.btn-brand-add:focus {
		outline-color: $cancel !important;
		// outline-color: #FFFFFF;
	}

	.btn-secondaryDetail {
		// border: none;
		// border-radius: 0.333rem;
		// background: $success-color;
		// color: $white !important;
		// width: 7rem;
		// height: 3rem;
		// cursor: pointer;
		// font-size: 1.142857rem;
		// margin-right: 1rem;
		padding: 0 20px;
		height: 33px;
		line-height: 34px;
		/* margin-top: 5px; */
		/* margin-bottom: 5px; */
		border: none;
		/* padding: 7px 14px; */
		/* border-radius: 0.333rem; */
		background: #45B6AF;
		color: #FFFFFF !important;
		/* width: 7rem; */
		/* height: 3rem; */
		cursor: pointer;
		font-size: 1.142857rem;
		margin-right: 0.5rem;
		// span {
		//   display: inline-block;
		//   height: 3rem;
		//   line-height: 3rem;
		// }
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-secondaryShowDetail {
		// border: none;
		// border-radius: 0.333rem;
		// background: $success-color;
		// color: $white !important;
		// width: 7rem;
		// height: 3rem;
		// cursor: pointer;
		// font-size: 1.142857rem;
		// margin-right: 1rem;
		padding: 0 20px;
		height: 33px;
		line-height: 34px;
		/* margin-top: 5px; */
		/* margin-bottom: 5px; */
		border: none;
		/* padding: 7px 14px; */
		/* border-radius: 0.333rem; */
		background: #3379B5;
		color: #FFFFFF !important;
		/* width: 7rem; */
		/* height: 3rem; */
		cursor: pointer;
		font-size: 1.142857rem;
		margin-right: 0.5rem;
		// span {
		//   display: inline-block;
		//   height: 3rem;
		//   line-height: 3rem;
		// }
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-stopDetail {
		// border: none;
		// border-radius: 0.333rem;
		// background: $success-color;
		// color: $white !important;
		// width: 7rem;
		// height: 3rem;
		// cursor: pointer;
		// font-size: 1.142857rem;
		// margin-right: 1rem;
		padding: 0 20px;
		height: 33px;
		line-height: 34px;
		/* margin-top: 5px; */
		/* margin-bottom: 5px; */
		border: none;
		/* padding: 7px 14px; */
		/* border-radius: 0.333rem; */
		background: #F3565D;
		color: #FFFFFF !important;
		/* width: 7rem; */
		/* height: 3rem; */
		cursor: pointer;
		font-size: 1.142857rem;
		margin-right: 0.5rem;
		// span {
		//   display: inline-block;
		//   height: 3rem;
		//   line-height: 3rem;
		// }
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	button:focus {
		outline: none;
	}

	.btn-secondary {
		border: none;
		border-radius: 0.333rem;
		background: $success-color;
		color: $white !important;
		width: 7rem;
		height: 3rem;
		cursor: pointer;
		font-size: 1.142857rem;
		margin-right: 1rem;
		span {
			display: inline-block;
			height: 3rem;
			line-height: 3rem;
		}
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-secondary:hover {
		background-color: #12938C !important;
	}

	

	.btn-brand-add {
		border: none;
		border-radius: 0.333rem;
		background: #00BFBF;
		color: #FFFFFF !important;
		width: 7rem;
		height: 3rem;
		cursor: pointer;
		font-size: 16px;
		span {
			display: inline-block;
			height: 3rem;
			line-height: 3rem;
		}
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.btn-brand-add:hover {
		background-color: #008A8A !important;
	}

	.btn-primary {
		border: none;
		border-radius: 0.333rem;
		background: $primary-color;
		color: #FFFFFF !important;
		width: 7rem;
		// height: 3rem;
		cursor: pointer;
		font-size: 16px;
		span {
			display: inline-block;
			height: 3rem;
			line-height: 3rem;
		}
		mat-icon {
			font-size: 22px;
			margin-left: 5px;
			height: 3rem;
			line-height: 3rem;
		}
	}

	.search-button-btn {
		width: 100%;
		text-align: right;
	}

	.search-button-btn-detail {
		width: 100%;
		text-align: center;
		background-color: #F5F5F5;
		border-top: 1px solid #E5E5E5;
		padding: 15px 25px !important;
	}
}

// 详情页样式
.detail-fields {
	color: #333333;
	// margin-bottom: 10px;
	padding: 15px;
	display: flex;
	flex-wrap: wrap;
	.search-form {
		line-height: 44px;
		width: 50%;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 1rem;
		font-size: 1rem !important;
		> div {
			width: 50%;
		}
	}
	> div:nth-child(5n + 5) {
		margin-right: 0;
	}
	.single-select,
	.multi-select {
		position: relative;
		mat-select,
		.autocomplete-input {
		//   max-width: 15.714286rem;
		// position: absolute;
		// left: 0;
		// top: 0;
		//   opacity: 0;
		padding-left: 0.714286rem;
		border: 1px solid $grey-1;
		border-radius: 0.357143rem;
	}
	.autocomplete-input {
		height: 3.285714rem;
	}
}
.title-style {
	font-size: 14px;
	margin-right: 20px;
	width: 40%;
	text-align: right;
	line-height: 45px;
	height: 43px;
	color: #333333;
	font-family: $primary-font-family;
}
}

/* 必填项提示 */
.required::after {
	content: "*";
	color: red;
	font-weight: bold;
	margin-left: pxToRem(5px);
	font-size: pxToRem(17px);
	font-family: $bold-font-family;
}

/* 额外提示 */
.attention::before {
	content: "*";
	color: red;
	font-weight: bold;
	margin-right: pxToRem(5px);
	font-size: pxToRem(17px);
	font-family: $bold-font-family;
}

// 校验失败边框颜色
.error-border {
	border-color: red !important;
}
.dropdown-error-border {
	.p-dropdown, .p-multiselect {
		border-color: red !important;
	}
}

// 详情页标题
.detail-title {
	color: #333333;
	font-size: 20px;
	margin: 20px;
	font-weight: bold;
}

::ng-deep {
	p-calendar {
		> span {
			width: 100%;
			> input {
				width: 100%;
			}
			.p-inputtext {
				font-size: pxToRem(16px);
			}
		}
	}
}

.single-select,
.multi-select {
	position: relative;
	mat-select {
	// max-width: 15.714286rem;
	// position: absolute;
	// left: 0;
	// top: 0;
	// opacity: 0;
	padding-left: 0.714286rem;
	border: 1px solid $grey-1;
	border-radius: 0.357143rem;
}
}

.mat-button {
	font-family: $bold-font-family;
	font-size: pxToRem(16px);
}

// 接口信息提示
.mat-simple-snackbar {
	font-family: $bold-font-family;
}
.mat-simple-snackbar span {
	font-size: pxToRem(17px);
}
.mat-simple-snackbar-action button {
	font-family: $bold-font-family;
}

// 系统$placeholderSystem
// table加载显示
mat-spinner {
	margin-left: calc(50% - 2.5rem);
	width: 5rem !important;
	height: 5rem !important;
	margin-bottom: 1rem;
}
.mat-progress-spinner circle,
.mat-spinner circle {
	stroke: $confirm-color !important;
}
.mat-progress-spinner svg {
	width: 5rem !important;
	height: 5rem !important;
}

// placeholder颜色
::-webkit-input-placeholder {
	/* WebKit browsers */
	font-family: $primary-font-family;
	color: $placeholderSystem !important;
	// font-size: 14px !important;
	font-weight: 500 !important;
}
:-moz-placeholder {
	/* Mozilla Firefox 4 to 18 */
	font-family: $primary-font-family;
	color: $placeholderSystem !important;
	// font-size: 14px !important;
	font-weight: 500 !important;
}
::-moz-placeholder {
	/* Mozilla Firefox 19+ */
	font-family: $primary-font-family;
	color: $placeholderSystem !important;
	// font-size: 14px !important;
	font-weight: 500 !important;
}
:-ms-input-placeholder {
	/* Internet Explorer 10+ */
	font-family: $primary-font-family;
	color: $placeholderSystem !important;
	// font-size: 14px !important;
	font-weight: 500 !important;
}

.page-title {
	padding: 0px;
	font-size: 28px;
	letter-spacing: -1px;
	display: block;
	margin: 0px 0px 15px 0px;
	font-weight: 500;
	color: #333333;
	/* subtitle */
}
.page-title small {
	font-size: 14px;
	letter-spacing: 0px;
	font-weight: 300;
	color: #888888;
}

.page-comment {
	color: #888888;
}

.page-container-bg-solid .page-title {
	color: #666666;
	margin-bottom: 20px;
	margin-top: 20px;
}
.page-container-bg-solid .page-title small {
	color: #666666;
}

// 给必填项目添加加粗
.font-weight-detail {
	font-weight: 700;
}

// 带搜索下拉
::ng-deep {
	.screening-dropdown {
		.p-multiselect {
			width: 100%;
			line-height: pxToRem(25px);
			border-radius: $radius;
		}
		.p-dropdown {
			width: 100%;
			line-height: pxToRem(25px);
			border-radius: $radius;
		}
		.p-multiselect-close {
			display: none;
		}
	}
}

.p-multiselect-item,
.p-dropdown-item {
	height: 100% !important;
}

::ng-deep {
	.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
	.mat-checkbox-checked.mat-accent .mat-checkbox-background {
		background: #578EBE;
	}
}

.radio-button-select {
	// margin-top: 10px;
}

nz-select {
	width: 100%;
}

.ant-select {
	color: #6C757D;
}

.ant-select-selection-placeholder {
	color: #6C757D;
}

.ant-select-selector {
	border-radius: 5px;
}


.none-line-height {
	line-height: 25px !important;
	margin-top: 10px !important;
}

.error-snack {
	border: 2px solid #E31937 !important;
	border-radius: pxToRem(12px) !important;
	background-color: #FFFFFF !important;
	color: #E31937 !important;

	.mat-simple-snackbar-action button {
		color: #E31937 !important;
	}
}

.success-snack {
	border: 2px solid #00ADEF !important;
	border-radius: pxToRem(12px) !important;
	background-color: #FFFFFF !important;
	color: #00ADEF !important;

	.mat-simple-snackbar-action button {
		color: #00ADEF !important;
	}
}

.multi-select-date {
	p-calendar > span {
		width: 45%;
	}
	> span {
		display: inline-block;
		width: 10%;
		text-align: center;
		font-size: 16px;
	}
}

.breadcrumb-section {
	height: pxToRem(18px);
	margin-bottom: pxToRem(10px);
	font-size: pxToRem(14px);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight,
.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item.p-highlight {
	background: $confirm-color;
}

.p-checkbox .p-checkbox-box.p-highlight,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
	background: $confirm-color;
	border-color: $white;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
	box-shadow: none;
}

.p-inputtext:enabled:focus {
	border-color: $confirm-color;
}

.selected-label {
	font-family: $primary-font-family;
}

.invisible {
	visibility: hidden;
}

.error-message {
	color: #E31937;
	font-size: 1rem;
}

// Universal page styling - HELM
.breadcrumb-section {
	.parent-page {
		text-decoration: underline;
		cursor: pointer;

		a {
			color: $black;
		}
	}

	.current-page {
		color: $confirm-color;
	}
}

.table-section {
	margin-top: pxToRem(16px);
}

.field-section {
	margin-top: pxToRem(16px);

	.field-row {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: pxToRem(36px);

		.field-display {
			.field-title {
				font-size: pxToRem(17px);
				margin-bottom: pxToRem(8px);
				font-family: $bold-font-family;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.field-display>div {
			margin-right: 2vw;
		}
	}
}

.button-section {
	margin-top: pxToRem(72px);
	margin-bottom: pxToRem(72px);

	button {
		margin-right: 2vw;
	}
}

.dialog-button-section {
	margin-top: pxToRem(72px);
	margin-bottom: pxToRem(36px);

	button {
		margin-right: 2vw;
	}
}

.title {
	margin-bottom: pxToRem(44px);
	font-family: $bold-font-family;
}

::ng-deep {
	// Data table bottom border
	#tab01 {
		margin-bottom: 1px;
	}
}